p {
    margin-bottom: 8px;
    text-align: justify;
}

hr {
    height: 1px;
    border: none;
    background-color: #00000080;
    margin-top: 13px;
}

.social-link-container {
    margin-top: 11px;
}

.social-link {
    margin-right: 20px;
}