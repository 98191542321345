.animation-container {
  position: relative;
  left: 10vw;
  top: -50px;
}


/* Unique objects */
.tree {
  height: 400px;
  width: 300px;
}

.center-dot {
  background: #f1c02f;
  border-radius: 50%;
  position: absolute;
  width: 4px;
  height: 4px;
	animation: pulse-double 7s infinite;
}

.plus {
  clip-path: polygon(10% 35%, 47% 35%, 47% 0%, 53% 0%, 53% 35%, 90% 35%, 90% 40%, 53% 40%, 53% 80%, 47% 80%, 47% 40%, 10% 40%);
  height: 14px;
  width: 14px;
  animation: opacity 5s infinite;
  animation-delay: .5s;
}

.arrow {
  clip-path: polygon(50% 0%, 100% 50%, 50% 10%, 0% 50%);
  animation: opacity 5s infinite;
  animation-delay: 1s;
}


/* Repeating objects */
.rhombus {
  position: absolute;
  animation: pulse 7s infinite;
}

.trunk-dot {
  background: #f1c02f;
  position: absolute;
  animation: opacity 6s infinite;
}


/* Particles */
.object {
  background: black;
  position: absolute;
}

.square {
  border-radius: 100% 0% 100% 0% / 0% 100% 0% 100%;
  animation: opacity 5s infinite;
}

.diamond {
  border-radius: 100% 0% 100% 0% / 0% 100% 0% 100%;
  transform: rotate(-45deg);
  animation: opacity 6s infinite;
}

.dot {
  border-radius: 50%;
  animation: opacity 5s infinite;
}

.star {
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  animation: opacity 4.5s infinite;
}


/* Creating randomness for particles */
.r0 {
  animation-delay: 1s;
}

.r1 {
  animation-delay: 4s; 
}

.r2 {
  animation-delay: 0.5s;
}

.r3 {
  animation-delay: 3s;
}

.r4 {
  animation-delay: 2s;
}

.r5 {
  animation-delay: 0s;
}


/* Staggering dots on the tree trunk */
.t0 {
  animation-delay: 0s;
}

.t1 {
  animation-delay: .2s;
}

.t2 {
  animation-delay: .4s;
}

.t3 {
  animation-delay: .6s;
}

.t4 {
  animation-delay: .8s;
}


/* Sizes of objects */
.x-small {
  width: 3px;
  height: 3px;
}

.small {
  width: 5px;
  height: 5px;
}

.medium {
  width: 7px;
  height: 7px;
}

.large {
  width: 8px;
  height: 8px;
}

.x-large {
  width: 10px;
  height: 10px;
}


/* Animations */
@keyframes pulse {
  0% {
    transform: scale(.95);
  }

	50% {
    transform: scale(.8);
	}

	100% {
    transform: scale(.95);
  }
}

@keyframes pulse-double {
  0% {
    transform: scale(0.5);
  }

	50% {
    transform: scale(1.25);
	}

	100% {
    transform: scale(0.5);
  }
}

@keyframes opacity {
  0% {
    opacity: 1;	}

	70% {
		opacity: 0;
	}

	100% {
		opacity: 1;
  }
}


/* Adjust position and scale of tree for screen size */
@media only screen and (max-width: 470px) {
  .animation-container {
    top: -12vh;
    left: -5vw;
    transform: scale(0.75);
  }
}

@media only screen and (min-width: 471px) and (max-width: 600px) {
  .animation-container {
    top: -50px;
    left: 4vw;
    transform: scale(0.85);
  }
}

@media only screen and (min-width: 1600px) {
  .animation-container {
    left: 7vw;
  }
}