.navbar {
    width: 100px;
    margin: 0;
    padding: 5px;
    list-style-type:none;
    font-size: 20px;
    text-align: right;
    letter-spacing:1px;
}

.navbar li {
    margin: 5px 0;
}

/* React injects active class into NavLink */
.active {
    font-style: italic;
    letter-spacing: .1rem;
}

.navlink {
    color: black;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .navbar {
    font-size: 14px;
  }
}